export default [
  {
    name: {
      en: "Sales generated",
      fr: "Ventes genérées",
      es: "Ventas generadas",
    },
    metric: "totalSales",
    tooltip: {
      en: "Number of sales where Vidjet has participated.",
      fr: "Nombre de ventes où Vidjet a participé.",
      es: "Número de ventas en las que Vidjet ha participado.",
    },
    subheaderMetric: "totalSalesCount",
    subheaderKeyword: {
      en: "sales",
      fr: "ventes",
      es: "ventas",
    },
    badgeTooltip: {
      en: "Sales comparison with the previous period.",
      fr: "Comparaison des ventes avec la période précédente.",
      es: "Comparación de ventas con el período anterior.",
    },
    color: "darkBlue",
    type: "amount",
    chartValue: "totalSalesCount",
    chartName: "dashboard.chartName.salesGenerated",
    badgeValue: "salesComparison",
    childrenMetrics: [
      {
        color: "darkBlue",
        name: {
          en: "Conversion rate",
          fr: "Taux de conversion",
          es: "Tasa de conversión",
        },
        type: "percentage",
        metric: "salesConversionRate",
        tooltip: {
          en: "Number of total viewers divided by the number of viewers purchasing after watching a video.",
          fr: "Nombre total de visiteurs ayant visionnés une vidéo divisé par le nombre de ces visiteurs ayant fait un achat par la suite.",
          es: "Número de visualizaciones totales dividido por el número de usuarios que compran después de ver un video.",
        },
      },
      {
        color: "darkBlue",
        name: {
          en: "Direct sales",
          fr: "Ventes directes",
          es: "Ventas directas",
        },
        type: "amount",
        metric: "directSales",
        optional: true,
        tooltip: {
          en: "Attribution criteria: when products are bought directly from your videos’ elements.",
          fr: "Critère d'attribution : lorsque les produits ont été achetés depuis les éléments sur vos vidéos.",
          es: "Criterio de atribución: cuando los productos se compraron a través de los elementos de sus videos.",
        },
      },
      {
        color: "darkBlue",
        name: {
          en: "Influenced sales",
          fr: "Ventes influencées",
          es: "Ventas influenciadas",
        },
        type: "amount",
        metric: "influencedSales",
        tooltip: {
          en: "Attribution criteria: a visitor watched at least one video during his session, and made a purchase during the same session.",
          fr: "Critère d'attribution : un visiteur a visionné au moins une vidéo pendant sa session, et a effectué un achat au cours de cette même session.",
          es: "Criterio de atribución: un visitante ha visualizado al menos un vídeo durante su sesión, y ha hecho una compra durante esa misma sesión.",
        },
      },
      {
        color: "darkBlue",
        name: {
          en: "Revenue per viewer",
          fr: "Revenu par téléspectateur",
          es: "Ingresos por visualizador",
        },
        type: "amount",
        metric: "revenuePerViewer",
        tooltip: {
          en: "The average revenue generated by viewers. It’s the ratio between sales generated, and the total number of viewers (visitors watching a video).",
          fr: "Moyenne du CA généné par téléspectateur. C'est le ratio entre les ventes générées et le nombre total de téléspectateurs (visiteurs regardant une vidéo).",
          es: "Ingresos medios generados por espectador. Es la relación entre las ventas generadas y el número total de espectadores (visitantes mirando un video).",
        },
      },
      {
        color: "darkBlue",
        name: {
          en: "Avg. order value",
          fr: "Panier moyen",
          es: "Valor medio del carrito",
        },
        type: "amount",
        metric: "avgOrderValue",
        tooltip: {
          en: "Calculation: total money generated divided by the number of sales generated.",
          fr: "Calcul : total du revenu généré, divisé par le nombre de ventes générées.",
          es: "Cálculo: ingresos totales generados, divididos por el número de ventas generadas.",
        },
      },
    ],
  },
  {
    name: {
      en: "Visitors exposed to Vidjet",
      fr: "Visiteurs exposés à Vidjet",
      es: "Visitantes expuestos a Vidjet",
    },
    metric: "percentageExposedToVidjet",
    tooltip: {
      en: "The percentage of your visitors that have been exposed to at least one Vidjet video throughout their session.",
      fr: "Pourcentage de vos visiteurs qui ont été exposé au moins à une vidéo Vidjet au cours de leur session.",
      es: "Porcentaje de sus visitantes que han estado expuestos al menos a un video de Vidjet a lo largo de su sesión .",
    },
    keyword: {
      en: "of traffic",
      fr: "du trafic",
      es: "del tráfico",
    },
    subheaderMetric: "visitorsExposedToVidjet",
    subheaderKeyword: {
      en: "visitors",
      fr: "visiteurs",
      es: "visitantes",
    },
    badgeTooltip: {
      en: "Comparison of visitors exposed to Vidjet with the previous period.",
      fr: "Comparaison des visitors exposés à Vidjet avec la période précédente.",
      es: "Comparación de visitantes expuestos a Vidjet en base al período anterior.",
    },
    color: "darkPurple",
    type: "percentage",
    chartValue: "campaignInteracted",
    chartName: "dashboard.chartName.visitorsExposed",
    badgeValue: "trafficExposedComparison",
    childrenMetrics: [
      {
        color: "darkPurple",
        name: {
          en: "Widgets play rate",
          fr: "Taux de visionnage des widgets",
          es: "Tasa de reproducción de los widgets",
        },
        metric: "campaignInteracted",
        metric2: "campaignDisplayed",
        tooltip: {
          en: "Number of times your visitors play ≥ 1 video from a widget. It’s the ratio between widgets plays (≥ 1 video has been watched) VS widgets impressions (no videos have been watched).",
          fr: "Nombre de fois où vos visiteurs visionnent ≥ 1 vidéo d'un widget. C'est le rapport entre les visionnages (≥ 1 vidéo a été vue) et les impressions (aucune vidéo n'a été vue).",
          es: "Número de veces que tus visitantes reproducen ≥ 1 vídeo de un widget. Es la relación entre reproducciones (≥ 1 vídeo se ha visto) e impresiones (no se ha visto ningún vídeo).",
        },
      },
      {
        color: "darkPurple",
        name: {
          en: "Videos played per viewer",
          fr: "Vidéos jouées par visiteur",
          es: "Videos reproducidos por visitante",
        },
        metric: "avgVideoPlayPerVisitor",
        tooltip: {
          en: "The average number of videos played by a visitor throughout his journey on your website, and watching at least 1 video.",
          fr: "Le nombre moyen de vidéos jouées par un visiteur tout au long de son parcours sur votre site web, et regardant au moins une vidéo.",
          es: "El número medio de videos reproducidos por un visitante a lo largo de su recorrido por su sitio web, y viendo al menos 1 vídeo.",
        },
      },
      {
        color: "darkPurple",
        name: {
          en: "View time per viewer",
          fr: "Temps de visionnage par visiteur",
          es: "Tiempo de visualización por visitante",
        },
        type: "time",
        metric: "avgViewDurationPerVisitor",
        tooltip: {
          en: "The average duration your visitors are actively watching a video.",
          fr: "La durée moyenne pendant laquelle vos visiteurs regardent activement une vidéo.",
          es: "La duración media que sus visitantes están viendo activamente un vídeo.",
        },
      },
    ],
  },
  {
    name: {
      en: "Video engagement",
      fr: "Engagement vidéo",
      es: "Engagement video",
    },
    metric: "videosPlayed",
    tooltip: {
      en: "Number of times your visitors click the play icon OR watch at least 4 seconds (if autoplay).",
      fr: "Nombre de fois que vos visiteurs cliquent sur l'icône 'play' OU visionnent au moins 4 secondes (si autoplay)",
      es: "Número de veces que sus visitantes hacen clic en el icono 'play' O miran al menos 4 segundos (en caso de autoplay).",
    },
    keyword: {
      en: "videos played",
      fr: "vidéos jouées",
      es: "videos reprod.",
    },
    subheaderMetric: "avgViewDuration",
    subheaderKeyword: {
      en: "avg. view duration",
      fr: "durée moyenne de visionnage",
      es: "duración media de visualización",
    },
    badgeTooltip: {
      en: "Comparison of videos played, based on the previous period.",
      fr: "Comparaison des vidéos jouées avec la période précédente.",
      es: "Comparación de videos reproducidos en base al período anterior.",
    },
    color: "lightPink",
    subHeaderType: "percentage",
    chartValue: "videosPlayed",
    chartName: "dashboard.chartName.videosPlayed",
    badgeValue: "videosPlayedComparison",
    childrenMetrics: [
      {
        color: "lightPink",
        name: {
          en: "Total time watched",
          fr: "Total du temps visionné",
          es: "Tiempo total visionado",
        },
        type: "time",
        metric: "videosWatchedTime",
        tooltip: {
          en: "Total time your viewers spent watching videos. It’s a good way to measure additional time spent on your website.",
          fr: "Temps total que vos visiteurs ont passé à regarder des vidéos. C'est un bon moyen de mesurer le temps supplémentaire passé sur votre site web.",
          es: "Tiempo total que sus visitantes pasaron viendo videos. Es una buena forma de medir el tiempo adicional que se pasa en su sitio web.",
        },
      },
      {
        color: "lightPink",
        name: {
          en: "Interactions with video elements",
          fr: "Intéraction avec les éléments vidéos",
          es: "Interacciones con elementos video",
        },
        metric: {
          en: [
            { label: "Tag product(s)", value: 3, shortName: "addToCart" },
            { label: "CTA clicks", value: 0, shortName: "ctaClick" },
            { label: "Email collector", value: 2, shortName: "emailCollected" },
          ],
          fr: [
            {
              label: "Produits",
              value: 3,
              shortName: "addToCart",
            },
            { label: "CTA clicks", value: 0, shortName: "ctaClick" },
            {
              label: "Collecteur d'e-mail",
              value: 2,
              shortName: "emailCollected",
            },
          ],
          es: [
            { label: "Productos", value: 3, shortName: "addToCart" },
            { label: "CTA clicks", value: 0, shortName: "ctaClick" },
            {
              label: "Recopilador de email",
              value: 2,
              shortName: "emailCollected",
            },
          ],
        },

        isSelectType: true,
        tooltip: {
          en: "Number of times your visitors interacted with elements on your videos (if any).",
          fr: "Nombre de fois où vos visiteurs ont interagi avec des éléments de vos vidéos (s'il y en a).",
          es: "Número de veces que sus visitantes interactuaron con elementos de sus videos (si los hubo).",
        },
      },
      {
        color: "lightPink",
        name: {
          en: "Number of times videos are shared",
          fr: "Nombre de partages des vidéos",
          es: "Número de veces que se comparten videos",
        },
        metric: "videoShared",
        tooltip: {
          en: "Videos come with a small 'share’ icon. This is the number of clicks on this icon.",
          fr: "Les vidéos viennent avec une petite icône de partage. Il s'agit du nombre de clics sur cette icône.",
          es: "Los videos vienen con un pequeño icono de 'compartir'. Este es el número de clics en este icono.",
        },
      },
    ],
  },
];
