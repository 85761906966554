import axios from "axios";

async function hasCacheShopData(token) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: {
      token,
    },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/integration/has-cache-shop-data`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getWebsiteWithStore(shop) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: {
      shop,
    },
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/integration/get-website-with-store`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function terminateShopifyIntegration(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data,
  };

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/shopify/terminate-integration`,
      requestOptions
    );
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getVideoImport(url, importer) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    params: {
      url,
    },
  };

  if (importer === 'youtube') {
    requestOptions.responseType = 'blob'
  }

  try {
    const res = await axios(
      `${process.env.VUE_APP_API_URL}/import-video/${importer}`,
      requestOptions
    );

    const disposition = res.headers['content-disposition'];
    const filenameMatch = disposition ? /filename="([^"]*)"/.exec(disposition) : null;
    const filename = filenameMatch ? filenameMatch[1] : 'video.mp4'; 

    return { filename, video: res.data };
  } catch (err) {
    throw err.response.data;
  }
}

export default {
  hasCacheShopData,
  getWebsiteWithStore,
  terminateShopifyIntegration,
  getVideoImport,
};
